import React, { ReactNode, FunctionComponent, useState } from "react";
import { Script } from "gatsby";
import "../styles/global.css";
interface Props {
  children: ReactNode;
}

const Root: FunctionComponent<Props> = ({ children }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {children}
      <Script
        id="donor-box-script"
        src="https://donorbox.org/install-popup-button.js"
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <Script id="donor-box-script-configuration">{`window.DonorBox = { widgetLinkClassName: 'donorbox-popup' }`}</Script>
      )}
    </>
  );
};

export default Root;
